@keyframes animateSvg {
  to {
    transform: translate(-50%, -10px);
  }
}

@keyframes upanddown {
  to {
    transform: translateY(-5px);
  }
}

@keyframes upupdowndown {
  to {
    transform: translateY(-10px);
  }
}
