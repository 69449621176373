#services {
  width: 100%;
  height: 100vh;
  padding-left: $p;
  margin-top: 1rem;
  background-color: $color5;
  color: $color4;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  > section {
    &:first-of-type {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;

      > article {
        border-bottom: 2px solid whitesmoke;
        padding-bottom: 1rem;
        > h4 {
          font: 600 2rem $text2;
          letter-spacing: 1px;
          margin-bottom: 1rem;
        }

        > p {
          font: 400 0.9rem $text2;
          letter-spacing: 1px;
        }
      }

      > aside {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;

        > h3 {
          font: 800 1.2rem $text2;
        }

        > div {
          > p {
            font: 400 1.05rem $text2;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }

          .imgWrapper1 {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 0.5rem 0;
            background-color: $color5_1;

            padding: 8px;
            border-radius: 8px;
            > img {
              transition: all 0.3s;
              padding: 0.27rem;
              height: 48px;
              width: 48px;

              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }

        > div {
          > p {
            font: 400 1.05rem $text2;
          }

          .imgWrapper2 {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            align-items: center;
            margin: 0.5rem 0;
            background-color: $color5_1;
            padding: 8px;
            border-radius: 8px;

            > img {
              transition: all 0.3s;
              padding: 0.45rem;
              height: 48px;
              width: 48px;

              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
      }

      > a {
        font-size: 0.8rem;
        color: $color1;
        transition: all 0.3s;

        &:hover {
          color: $color3;
        }
      }
    }
    &:last-of-type {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        height: 35vmax;
        width: 100%;
        background: transparent;
        margin: 5px;
      }
    }
  }
}
