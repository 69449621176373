#home {
  width: 100%;
  height: 90%;
  display: flex;

  > section {
    width: 100%;
    &:first-of-type {
      padding-left: $p;
      > div {
        border-top: 1px solid $color6_2;
        height: 100%;
        padding-top: 6rem;
        > h1 {
          font: 900 4rem $text1;
        }
        .typewriterpara {
          margin: 20px 0;
          height: 20px;
          letter-spacing: 5px;
        }

        > div {
          display: flex;
          margin: 3.8rem 0;
          width: 70%;
          gap: 4rem;
          align-items: center;
          > a {
            &:first-of-type {
              background-color: $color3;
              color: $color1;
              padding: 1rem 2.5rem;
              transition: all 0.3s;
              &:hover {
                background-color: $color3_1;
              }
            }
            &:last-of-type {
              font: 600 1.2rem $text2;
              color: $color5;
              display: flex;
              align-items: center;
            }
          }
        }

        .window {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: $color1;
          border: 1px solid $color3;
          border-radius: 5px;
          box-shadow: 0 0 7px $color6_1;
          width: 80%;
          height: auto;

          > div {
            width: 100%;
            padding: 1.2rem 1rem;
            background-color: $color3;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $color3;
            height: 2em;

            > div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .dot {
                padding: 0.4rem;
                margin: 0 -2px;
                border-radius: 50%;
              }
            }

            > div {
              font: 600 1rem $text2;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 15px;
              a {
                color: $color1;
                transition: all 0.3s;

                &:hover {
                  color: $color6;
                }
              }
            }
          }

          > article {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
            > h3 {
              font: 700 2rem $text2;
              letter-spacing: 2px;
              margin-top: -4rem;
            }
            > p {
              font: 400 1.5rem $text2;
              padding-top: 1rem;
            }
          }
        }

        article {
          margin: 2rem 0;
          width: 100%;
          color: $color5;
          position: relative;

          > p {
            font: 600 1.5rem $text1;
          }
        }
      }
    }

    &:last-of-type {
      border-top: 1px solid $color6_2;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      overflow-x: hidden;
      position: relative;
      gap: 1rem;

      .altWindow {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $color1;
        border: 1px solid $color3;
        border-radius: 5px;
        box-shadow: 0 0 7px $color6_1;
        width: 80%;
        height: fit-content;
        margin-bottom: 2rem;

        > div {
          width: 100%;
          height: 10%;
          padding: 1.2rem 1rem;
          background-color: $color3;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $color3;
          height: 2em;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .dot {
              padding: 0.4rem;
              margin: 0 -2px;
              border-radius: 50%;
            }
          }

          > div {
            font: 600 1rem $text2;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            a {
              color: $color1;
              transition: all 0.3s;

              &:hover {
                color: $color6;
              }
            }
          }
        }

        > article {
          width: 100%;
          height: 90%;
          display: flex;
          flex-direction: column;
          padding: 0 1rem;
          > h3 {
            font: 700 2rem $text2;
            letter-spacing: 2px;
            margin-top: 1rem;
          }
          > p {
            font: 400 1.5rem $text2;
            padding: 1rem 0;
          }
        }
      }

      > div {
        height: 50%;
        background-color: $color3;
        border-radius: 50%;
        width: 38vmax;
        height: 38vmax;
        overflow-y: hidden;
        overflow-x: hidden;
        /* position: absolute;
        top: 20%; */
        /*    &::after {
          content: "";
          width: 38vmax;
          height: 38vmax;
          background-color: $color3;
          position: absolute;
          z-index: -1;
          border-radius: 50%;
        } */
        > img {
          width: 40vmax;
          height: 40vmax;
          object-fit: cover;
        }
      }

      .altH1 {
        display: none;
        font: 900 4rem $text1;
      }
    }
  }
  > svg {
    position: absolute;
    font-size: 4rem;
    color: $color5;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
    animation: animateSvg 0.7s infinite linear alternate;
  }
}
