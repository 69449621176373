#work {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150vh;
  overflow: hidden;
  > h2 {
    font: 600 2rem $text2;
    border-bottom: 3px solid gray;
    margin-bottom: 1rem;
  }
  > section {
    > article {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      place-items: center;
      overflow-y: hidden;
      .workItem {
        border: 2px solid $color3;
        background-color: $color1_2;
        border-radius: 10px;
        width: 25rem;
        height: 28rem;
        padding: 10px;
        margin: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-flow: column;
        padding: 1rem;
        .imageWrapper {
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            display: block;
            width: 100%;
            height: 12rem;
            object-fit: cover;
          }
        }
        > aside {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 1rem;
          height: 50%;
          > h3 {
            font: 600 1.5rem $text2;
          }
          > p {
            font: 400 1rem $text2;
            letter-spacing: 0.5px;
            padding: 0.5rem 0;
            margin-bottom: 1rem;
          }
          > a {
            background-color: $color3;
            color: $color1;
            padding: 0.5rem 1rem;
            transition: all 0.3s;

            &:hover {
              background-color: $color3_1;
            }
          }
        }
      }
    }
  }
}
